export default function enquiryUtils() {
  const predefinedEnquiryTags = [
    {
      label: "Follow Up",
      value: "followup",
      theme: "bg-blue-50 text-blue-700",
    },
    { label: "Hot", value: "hot", theme: "bg-red-50 text-red-700" },
    { label: "VIP", value: "vip", theme: "bg-yellow-50 text-yellow-700" },
  ];
  return {
    predefinedEnquiryTags,
  };
}
