import React, { useState } from "react";
import TimePickerModal from "./TimePickerModal";

export default function ReadyTimeField({ states, setStates }) {
  const [showTimeModal, setShowTimeModal] = useState(false);

  return (
    <>
      <input
        type="text"
        readOnly
        className="border  rounded px-2 py-1 text-sm flex-1"
        placeholder="Ready by"
        value={states.readyTime || ""}
        onClick={() => setShowTimeModal(true)}
      />

      {showTimeModal && (
        <TimePickerModal
          onClose={() => setShowTimeModal(false)}
          onSelectTime={(time) => {
            setStates((prev) => ({ ...prev, readyTime: time }));
            setShowTimeModal(false);
          }}
        />
      )}
    </>
  );
}
