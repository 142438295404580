import React, { useState, useEffect } from "react";
import moment from "moment";
import classNames from "classnames";

const HOURS = [
  "12:00 AM",
  "1:00 AM",
  "2:00 AM",
  "3:00 AM",
  "4:00 AM",
  "5:00 AM",
  "6:00 AM",
  "7:00 AM",
  "8:00 AM",
  "9:00 AM",
  "10:00 AM",
  "11:00 AM",
  "12:00 PM",
  "1:00 PM",
  "2:00 PM",
  "3:00 PM",
  "4:00 PM",
  "5:00 PM",
  "6:00 PM",
  "7:00 PM",
  "8:00 PM",
  "9:00 PM",
  "10:00 PM",
  "11:00 PM",
];

// Based on your “extracted” logic:
function getTimePeriod(timeString) {
  const hour = moment(timeString, "h:mm A").hour(); // 0..23

  // Mid Night: 0..6 (technically 12am - 6am)
  if (hour >= 0 && hour <= 6) {
    return "midnight";
  }

  // Morning: 6..12 (6am - 12pm)
  if (hour > 6 && hour <= 12) {
    return "morning";
  }

  // Afternoon: 12..16 (12pm - 4pm)
  if (hour > 12 && hour <= 16) {
    return "afternoon";
  }

  // Evening: 16..19 (4pm - 7pm)
  if (hour > 16 && hour <= 19) {
    return "evening";
  }

  // Night: >19 (8pm onward)
  return "night";
}

// Your five “chips” in the order you requested.
// (You can reorder them to match your UX preference.)
const TIME_CHIPS = [
  { label: "Morning", value: "morning" },
  { label: "Afternoon", value: "afternoon" },
  { label: "Evening", value: "evening" },
  { label: "Night", value: "night" },
  { label: "Mid Night", value: "midnight" },
];

export default function TimePickerModal({
  onClose,
  onSelectTime,
  selectedReadyTime, // selected time (e.g. "10:00 AM")
}) {
  // Default to “Evening” if no selectedReadyTime is provided
  const [activeChip, setActiveChip] = useState("evening");

  useEffect(() => {
    if (selectedReadyTime) {
      const period = getTimePeriod(selectedReadyTime);
      setActiveChip(period);
    }
  }, [selectedReadyTime]);

  // Filter HOURS to only those in the active chip
  const filteredHours = HOURS.filter((timeStr) => {
    return getTimePeriod(timeStr) === activeChip;
  });

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Overlay */}
      <div
        className="absolute inset-0 bg-black bg-opacity-50"
        onClick={onClose}
      />

      <div className="relative z-10 bg-white p-4 rounded shadow max-w-[95vw] flex-shrink-0 max-h-[70vh] overflow-auto">
        <h3 className="text-base font-semibold mb-4">Select Ready Time</h3>

        {/* Chips */}
        <div className="flex gap-2 mb-4">
          {TIME_CHIPS.map((chip) => (
            <button
              key={chip.value}
              onClick={() => setActiveChip(chip.value)}
              className={classNames("px-3 py-1 text-sm rounded-full border", {
                "bg-blue-500 text-white border-blue-500":
                  activeChip === chip.value,
                "bg-white text-gray-700 border-gray-300":
                  activeChip !== chip.value,
              })}
            >
              {chip.label}
            </button>
          ))}
        </div>

        {/* Time slots */}
        <div className="grid grid-cols-3 gap-2">
          {filteredHours.map((hour) => {
            const isSelected = hour === selectedReadyTime;
            return (
              <button
                key={hour}
                onClick={() => onSelectTime(hour)}
                className={classNames(
                  "w-full py-2 text-sm rounded border border-gray-200 hover:bg-gray-100",
                  { "bg-blue-500 text-white border-blue-500": isSelected }
                )}
              >
                {hour}
              </button>
            );
          })}
        </div>

        {/* Cancel button */}
        <button
          onClick={onClose}
          className="mt-4 w-full border bg-gray-100 rounded py-2 text-sm hover:bg-gray-200"
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
