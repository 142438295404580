import React, { useCallback } from "react";
import createNewEnquiryHelper from "../f/createNewEnquiryHelper";
import { useAdminContext } from "../../../../context/AdminContext";

export default function Bottom({ states, height, setStates }) {
  const {
    adminState: { adminInfo },
  } = useAdminContext();

  // Encapsulate the click handler in a useCallback (optional optimization)
  const handleClick = useCallback(() => {
    // Call your async logic (handleAssignToMe)
    createNewEnquiryHelper().handleAssignToMe({
      states,
      setStates,
      expert: adminInfo,
    });
  }, [states, setStates, adminInfo]);

  return (
    <div
      className="absolute bottom-0 left-0 px-2 pb-2 right-0 flex-center"
      style={{ height }}
    >
      <button
        className="blue-gradient text-sm font-semibold p-2 w-full flex-center h-full rounded-lg"
        onClick={handleClick}
        // Optionally, disable the button while isCreating is true
        disabled={states.isCreating}
      >
        {states.isCreating ? (
          <div
            className="loader w-[1rem]"
            style={{
              borderTopColor: "white",
            }}
          ></div>
        ) : (
          "Assign to Me"
        )}
      </button>
    </div>
  );
}
