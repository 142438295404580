import React from "react";
import { motion } from "framer-motion";
import NoteCard from "./NoteCard";

/**
 * Renders a list of existing notes.
 * Splits out each note into its own <NoteCard /> subcomponent.
 */
export default function NotesList({
  notes,
  setNotes,
  adminInfo,
  onNotesUpdate,
  selectedNoteIdx,
  setSelectedNoteIdx,
}) {
  return (
    <>
      {notes.map((noteData, idx) => (
        <NoteCard
          key={idx}
          noteIndex={idx}
          noteData={noteData}
          notes={notes}
          setNotes={setNotes}
          adminInfo={adminInfo}
          onNotesUpdate={onNotesUpdate}
          {...{ selectedNoteIdx, setSelectedNoteIdx }}
        />
      ))}
    </>
  );
}
