import React from "react";

export default function AreaField({ states, setStates }) {
  return (
    <div className="space-y-1">
      <label className="block text-sm font-semibold">Area</label>
      <input
        type="text"
        className="border rounded px-2 py-1 text-sm w-full"
        placeholder="E.g. Thane, Church gate, etc."
        value={states.area || ""}
        onChange={(e) =>
          setStates((prev) => ({ ...prev, area: e.target.value }))
        }
      />
    </div>
  );
}
