import React from "react";

export default function BudgetField({ states, setStates }) {
  const [minBudget, maxBudget] = states.budget || [0, 0];

  const handleMinChange = (e) => {
    const val = e.target.value.replace(/\D/g, ""); // numeric only
    setStates((prev) => ({
      ...prev,
      budget: [Number(val || 0), prev.budget[1]],
    }));
  };

  const handleMaxChange = (e) => {
    const val = e.target.value.replace(/\D/g, ""); // numeric only
    setStates((prev) => ({
      ...prev,
      budget: [prev.budget[0], Number(val || 0)],
    }));
  };

  return (
    <div className="space-y-1">
      <label className="block text-sm font-semibold">Budget</label>
      <div className="flex gap-2">
        <input
          className="border rounded px-2 py-1 text-sm w-1/2"
          type="text"
          placeholder="Min"
          value={minBudget || ""}
          onChange={handleMinChange}
        />
        <input
          className="border rounded px-2 py-1 text-sm w-1/2"
          type="text"
          placeholder="Max"
          value={maxBudget || ""}
          onChange={handleMaxChange}
        />
      </div>
    </div>
  );
}
