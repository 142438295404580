import React from "react";

export default function OccasionField({
  states,
  setStates,
  predefinedOccasions,
  isOccasionOther,
  setIsOccasionOther,
}) {
  const handleOccasionChange = (e) => {
    const val = e.target.value;
    if (val === "other") {
      setIsOccasionOther(true);
      setStates((prev) => ({ ...prev, occasionName: "" }));
    } else {
      setIsOccasionOther(false);
      setStates((prev) => ({ ...prev, occasionName: val }));
    }
  };

  return (
    <div className="space-y-1">
      <label className="block text-sm font-semibold">Occasion</label>
      {!isOccasionOther ? (
        <select
          className="border rounded px-2 py-1 w-full text-sm"
          value={
            predefinedOccasions.some((o) => o.value === states.occasionName)
              ? states.occasionName
              : "other"
          }
          onChange={handleOccasionChange}
        >
          <option value="">--Select Occasion--</option>
          {predefinedOccasions.map((oc) => (
            <option key={oc.value} value={oc.value}>
              {oc.label}
            </option>
          ))}
        </select>
      ) : (
        <div className="flex items-center gap-2">
          <input
            type="text"
            className="border rounded px-2 py-1 text-sm flex-1"
            placeholder="Enter custom occasion"
            value={states.occasionName}
            onChange={(e) =>
              setStates((prev) => ({ ...prev, occasionName: e.target.value }))
            }
          />
          <button
            type="button"
            className="text-xs bg-gray-100 px-2 py-1 rounded hover:bg-gray-200 border"
            onClick={() => {
              setIsOccasionOther(false);
              setStates((prev) => ({ ...prev, occasionName: "" }));
            }}
          >
            X
          </button>
        </div>
      )}
    </div>
  );
}
