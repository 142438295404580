import React, { useState } from "react";
import editEnquiryHelper from "../f/editEnquiryHelper";

/**
 * Displays ALL admins. The user can pick one, then confirm assignment.
 */
export default function ExpertSelectModal({
  onClose,
  enquiryId,
  assignedExperts,
  admins,
  onUpdateAssignedExperts,
}) {
  const [selectedExpertId, setSelectedExpertId] = useState("");
  const [selectedExpert, setSelectedExpert] = useState(null);
  const [isAssigning, setIsAssigning] = useState(false);

  // Called when we confirm the new assignment
  const handleAssign = () => {
    if (!selectedExpertId) return;

    setIsAssigning(true);

    // Build the new assignedExperts array
    // We'll push a new assignment object at the end
    const newAssignedExperts = [
      ...assignedExperts,
      {
        expert: selectedExpert, // We'll store minimal fields here
        date: new Date(),
      },
    ];

    // In your DB doc, we only store the expert ID. e.g. { expert: selectedExpertId, date: new Date() }
    // So let's build that for the DB update:
    const dbAssignedExperts = newAssignedExperts.map((ae) => ({
      expert: ae.expert._id,
      date: ae.date,
      tags: ae.tags || [],
    }));

    editEnquiryHelper()
      .editEnquiry(enquiryId, { assignedExperts: dbAssignedExperts })
      .then((res) => {
        if (res.isSuccess && res.enquiry) {
          // Update parent state
          onUpdateAssignedExperts(newAssignedExperts);
          onClose();
        }
      })
      .finally(() => {
        setIsAssigning(false);
      });
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Dim background */}
      <div
        className="absolute inset-0 bg-black bg-opacity-50"
        onClick={onClose}
      />

      {/* Modal content */}
      <div className="relative z-10 bg-white w-[320px] rounded shadow flex flex-col">
        {/* Header */}
        <div className="p-2 border-b flex justify-between items-center">
          <h2 className="text-sm font-semibold">Select an Expert</h2>
          <button
            className="text-gray-600 hover:text-gray-900 text-sm"
            onClick={onClose}
          >
            X
          </button>
        </div>

        {/* Expert List */}
        <div className="flex flex-col max-h-[300px] overflow-y-auto divide-y">
          {admins.map((adm) => (
            <ExpertItem
              key={adm._id}
              admin={adm}
              isSelected={adm._id === selectedExpertId}
              onClick={() => {
                setSelectedExpertId(adm._id);
                setSelectedExpert(adm);
              }}
            />
          ))}
        </div>

        {/* Footer */}
        <div className="p-2 border-t flex justify-end">
          <button
            onClick={handleAssign}
            disabled={!selectedExpertId || isAssigning}
            className="bg-blue-600 text-white text-sm px-3 py-1 rounded hover:bg-blue-700 disabled:opacity-50"
          >
            {isAssigning ? "Assigning..." : "Assign Expert"}
          </button>
        </div>
      </div>
    </div>
  );
}

/** One row in the list of all experts. */
function ExpertItem({ admin, isSelected, onClick }) {
  return (
    <div
      className={`p-2 text-sm cursor-pointer flex items-center justify-between ${
        isSelected ? "bg-blue-50" : ""
      }`}
      onClick={onClick}
    >
      <div className="flex flex-col">
        <span className="font-medium">{admin.userName}</span>
        <span className="text-xs text-gray-600">{admin.mobile}</span>
      </div>
      {isSelected && (
        <span className="text-xs text-blue-700 font-semibold">Selected</span>
      )}
    </div>
  );
}
