import React, { useState } from "react";
import { FaUserShield } from "react-icons/fa";
import ExpertSelectModal from "./ExpertSelectModal";

/**
 * This modal shows:
 *  - a list of previously assigned experts
 *  - highlight the current expert (the last in the list)
 *  - a "Reassign" button => opens ExpertSelectModal
 */
export default function ExpertAssignmentModal({
  onClose,
  enquiryId,
  assignedExperts,
  admins,
  onUpdateAssignedExperts,
}) {
  const [showSelectModal, setShowSelectModal] = useState(false);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Dim Background */}
      <div
        className="absolute inset-0 bg-black bg-opacity-50"
        onClick={onClose}
      />

      {/* Modal Panel */}
      <div className="relative z-10 bg-white w-[340px] rounded shadow-sm flex flex-col">
        {/* Header */}
        <div className="p-2 border-b flex justify-between items-center">
          <div className="flex items-center space-x-1 text-sm font-semibold">
            <FaUserShield />
            <span>Assigned Experts</span>
          </div>
          <button
            className="text-gray-600 hover:text-gray-900 text-sm"
            onClick={onClose}
          >
            X
          </button>
        </div>

        {/* Assigned Experts List */}
        <div className="flex flex-col max-h-[250px] overflow-y-auto divide-y">
          {assignedExperts?.length > 0 ? (
            assignedExperts.map((exp, idx) => {
              const isCurrent = idx === assignedExperts.length - 1;
              return (
                <AssignedExpertRow
                  key={exp._id || idx}
                  assignedExpert={exp}
                  isCurrent={isCurrent}
                />
              );
            })
          ) : (
            <div className="py-2 px-3 text-sm text-gray-500">
              No experts assigned yet
            </div>
          )}
        </div>

        {/* Footer */}
        <div className="p-2 border-t flex justify-end gap-2">
          <button
            onClick={() => setShowSelectModal(true)}
            className="bg-blue-600 text-white text-sm px-3 py-1 rounded hover:bg-blue-700"
          >
            {assignedExperts.length ? "Reassign Expert" : "Assign Expert"}
          </button>
        </div>
      </div>

      {/* The second modal for selecting from all admins */}
      {showSelectModal && (
        <ExpertSelectModal
          onClose={() => setShowSelectModal(false)}
          enquiryId={enquiryId}
          assignedExperts={assignedExperts}
          admins={admins}
          onUpdateAssignedExperts={onUpdateAssignedExperts}
        />
      )}
    </div>
  );
}

/** A row to display a previously assigned expert. */
function AssignedExpertRow({ assignedExpert, isCurrent }) {
  // Because 'assignedExperts' is already populated, we have .expert as an object:
  // { _id, userName, mobile }
  const { expert, date } = assignedExpert;
  if (!expert) return null;

  const assignmentDate = date
    ? new Date(date).toLocaleString()
    : "No date info";

  return (
    <div
      className={`py-2 px-3 text-sm flex items-center justify-between ${
        isCurrent ? "bg-blue-50" : ""
      }`}
    >
      <div>
        <div className="font-medium">{expert.userName}</div>
        <div className="text-xs text-gray-600">{expert.mobile}</div>
      </div>
      <div className="text-xs text-gray-500">{assignmentDate}</div>
      {isCurrent && (
        <span className="ml-2 text-xs font-semibold text-blue-700">
          Current
        </span>
      )}
    </div>
  );
}
