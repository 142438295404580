import React, { useState } from "react";
import { motion } from "framer-motion";
import moment from "moment";

// You can reuse the same color palettes if you like:
const colorPallets = [
  ["bg-orange-100", "text-orange-800", "border-orange-400"],
  ["bg-blue-100", "text-blue-800", "border-blue-300"],
  ["bg-green-100", "text-green-800", "border-green-300"],
  ["bg-yellow-100", "text-yellow-800", "border-yellow-300"],
  ["bg-red-100", "text-red-800", "border-red-300"],
  ["bg-indigo-100", "text-indigo-800", "border-indigo-300"],
  ["bg-purple-100", "text-purple-800", "border-purple-300"],
  ["bg-pink-100", "text-pink-800", "border-pink-300"],
  ["bg-gray-100", "text-gray-800", "border-gray-300"],
  ["bg-blue-100", "text-blue-800", "border-blue-300"],
  ["bg-green-100", "text-green-800", "border-green-300"],
  ["bg-yellow-100", "text-yellow-800", "border-yellow-300"],
  ["bg-red-100", "text-red-800", "border-red-300"],
  ["bg-indigo-100", "text-indigo-800", "border-indigo-300"],
  ["bg-purple-100", "text-purple-800", "border-purple-300"],
];

export default function NewNoteForm({
  notes,
  setNotes,
  adminInfo,
  onNotesUpdate,
  setSelectedNoteIdx,
}) {
  const [newNote, setNewNote] = useState({ title: "", note: "", tags: [] });

  // We'll pick a color based on the *current* length of the notes array
  const paletteIndex = notes.length % colorPallets.length;
  const [bgColor, textColor, borderColor] = colorPallets[paletteIndex];

  // “Add Note” action
  const handleAddNote = () => {
    if (!newNote.title || !newNote.note) {
      alert("Title and note are required");
      return;
    }

    const noteToAdd = {
      ...newNote,
      createdAt: moment().toISOString(),
      admin: { _id: adminInfo._id, userName: adminInfo.userName },
      replies: [],
    };

    // 1) update local state
    const newNotes = [...notes, noteToAdd];
    setNotes(newNotes);

    // 2) Fire callback with stripped version
    onNotesUpdate(stripLocalFields(newNotes));

    // 3) reset
    setNewNote({ title: "", note: "", tags: [] });
  };

  // Utility: remove “admin” object with just `_id`
  function stripLocalFields(allNotes) {
    return allNotes.map((n) => ({
      note: n.note,
      title: n.title,
      createdAt: n.createdAt,
      tags: n.tags || [],
      admin: typeof n.admin === "object" ? n.admin._id : n.admin,
      replies: (n.replies || []).map((r) => ({
        reply: r.reply,
        createdAt: r.createdAt,
        admin: typeof r.admin === "object" ? r.admin._id : r.admin,
      })),
    }));
  }

  return (
    <motion.div
      initial={{ y: 20 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.3, ease: "linear" }}
      onClick={(ev) => {
        // We don't want to close anything here,
        // but you could “unselect” existing notes if you wish
        ev.stopPropagation();
        setSelectedNoteIdx(null);
      }}
      className={`w-full ${bgColor} ${textColor} border-white border-2 rounded-xl p-4 flex flex-col overflow-hidden drop-shadow`}
    >
      {/* Title Input */}
      <div className="flex items-center">
        <div className="w-full flex items-center gap-2 relative">
          <input
            type="text"
            placeholder="Title"
            value={newNote.title}
            onChange={(e) =>
              setNewNote((prev) => ({ ...prev, title: e.target.value }))
            }
            className={`${bgColor} w-full min-w-full text-base font-[500] rounded-lg`}
          />
        </div>
      </div>

      {/* Note content */}
      <textarea
        placeholder="Note..."
        value={newNote.note}
        onChange={(e) =>
          setNewNote((prev) => ({ ...prev, note: e.target.value }))
        }
        className={`${bgColor} mt-2 w-full min-w-full text-sm min-h-[5rem] overflow-scroll`}
      />

      {/* Add Button */}
      <div className="w-full flex items-center justify-end">
        <div
          onClick={handleAddNote}
          className={`${borderColor} border px-4 py-1 font-[600] rounded-full cursor-pointer`}
        >
          Add
        </div>
      </div>
    </motion.div>
  );
}
