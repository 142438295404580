import React, { useState, useRef, useEffect } from "react";
import { FaFlag } from "react-icons/fa";

/**
 * Example usage:
 *   <StatusPicker states={states} setStates={setStates} />
 *
 * - Expects `states.status` to be one of ["open", "dropped", "converted", "scheduled", "pending"].
 * - Clicking the “current status” opens a custom dropdown with color-coded statuses.
 * - On selection, calls `setStates` to update `status`.
 */

export default function StatusPicker({ states, setStates }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const wrapperRef = useRef(null);

  // Close dropdown if clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Define all possible statuses with colors/icons
  const STATUS_OPTIONS = [
    {
      value: "open",
      label: "Open",
      colorClass: "bg-green-50 text-green-800",
      iconClass: "text-green-500",
    },
    {
      value: "pending",
      label: "Pending",
      colorClass: "bg-yellow-50 text-yellow-800",
      iconClass: "text-yellow-500",
    },
    {
      value: "scheduled",
      label: "Scheduled",
      colorClass: "bg-blue-50 text-blue-800",
      iconClass: "text-blue-500",
    },
    {
      value: "converted",
      label: "Converted",
      colorClass: "bg-purple-50 text-purple-800",
      iconClass: "text-purple-500",
    },
    {
      value: "dropped",
      label: "Dropped",
      colorClass: "bg-red-50 text-red-800",
      iconClass: "text-red-500",
    },
  ];

  // Find current status object
  const currentStatus =
    STATUS_OPTIONS.find((opt) => opt.value === states.status) ||
    STATUS_OPTIONS[0]; // fallback

  // Handle user selecting a new status
  const handleSelectStatus = (newValue) => {
    setStates((prev) => ({ ...prev, status: newValue }));
    setShowDropdown(false);
  };

  return (
    <div className="relative inline-block" ref={wrapperRef}>
      {/* The "button" that shows current status */}
      <button
        type="button"
        onClick={() => setShowDropdown((prev) => !prev)}
        className={`flex items-center px-2 py-1 text-sm border rounded-md ${currentStatus.colorClass} border-gray-300 hover:bg-gray-100`}
      >
        {/* Icon */}
        <FaFlag className={`${currentStatus.iconClass} mr-1`} />
        {/* Label */}
        {currentStatus.label}
        {/* Down arrow icon or chevron (optional) */}
        <svg
          className="ml-2 w-4 h-4 text-gray-600"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          viewBox="0 0 24 24"
        >
          <path d="M19 9l-7 7-7-7" />
        </svg>
      </button>

      {/* Dropdown list */}
      {showDropdown && (
        <div className="absolute left-0 mt-1 w-40 bg-white border border-gray-200 rounded shadow z-20">
          {STATUS_OPTIONS.map((opt) => (
            <div
              key={opt.value}
              className={`flex items-center px-2 py-2 text-sm cursor-pointer hover:bg-gray-100 ${
                opt.value === states.status ? "bg-gray-50" : ""
              }`}
              onClick={() => handleSelectStatus(opt.value)}
            >
              <FaFlag className={`${opt.iconClass} mr-2`} />
              <span>{opt.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
