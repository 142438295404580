import React from "react";
import { FaUserAlt } from "react-icons/fa";

export default function UserHeader({ userId }) {
  if (!userId || !userId.name) return null;
  return (
    <div className="flex items-center space-x-2 flex-shrink-0 p-2 bg-gray-100 rounded-md">
      <FaUserAlt />
      <div className="flex flex-col text-sm">
        <span className="font-semibold">{userId.name}</span>
        <span className="text-gray-600">{userId.phoneNumber}</span>
      </div>
    </div>
  );
}
