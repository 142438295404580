import React from "react";

export default function CityField({
  states,
  setStates,
  cityOptions,
  isCityOther,
  setIsCityOther,
}) {
  const handleCityChange = (e) => {
    const val = e.target.value;
    if (val === "other") {
      setIsCityOther(true);
      setStates((prev) => ({ ...prev, city: "" }));
    } else {
      setIsCityOther(false);
      setStates((prev) => ({ ...prev, city: val }));
    }
  };

  // Helper to check if the city is in cityOptions
  const cityInOptions = cityOptions.some((c) => c.city === states.city);

  return (
    <div className="space-y-1">
      <label className="block text-sm font-semibold">City</label>
      {!isCityOther ? (
        <select
          className="border rounded px-2 py-1 w-full text-sm"
          value={cityInOptions ? states.city : "other"}
          onChange={handleCityChange}
        >
          <option value="">--Select City--</option>
          {cityOptions.map((c) => (
            <option key={c.city} value={c.city}>
              {c.label}
            </option>
          ))}
        </select>
      ) : (
        <div className="flex items-center gap-2">
          <input
            type="text"
            className="border rounded px-2 py-1 text-sm flex-1"
            placeholder="Enter custom city"
            value={states.city}
            onChange={(e) =>
              setStates((prev) => ({ ...prev, city: e.target.value }))
            }
          />
          <button
            type="button"
            className="text-xs bg-gray-100 px-2 py-1 rounded hover:bg-gray-200 border"
            onClick={() => {
              setIsCityOther(false);
              setStates((prev) => ({ ...prev, city: "" }));
            }}
          >
            X
          </button>
        </div>
      )}
    </div>
  );
}
