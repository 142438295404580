import axios from "axios";
import React from "react";
class ErrorBoundaries extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  async componentDidCatch(error, info) {
    const errorLogData = {
      message: error?.message,
      errorFromSiteName: "admin.ebonow.com",
      catchErrorObject: {
        message: error?.message,
        name: error?.name,
        stack: error?.stack,
        data: error?.data,
        resData: error?.response?.data,
        resStatus: error?.response?.status,
        resMessage: error?.response?.message,
      },
      tags: ["error-boundary"],
    };

    await axios.post(process.env.REACT_APP_API_URL + "/api/logger", {
      logData: JSON.stringify(errorLogData),
      logType: "error",
    });

    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }
    return this.props.children;
  }
}

export default ErrorBoundaries;
