import React from "react";

export default function CelebrationDateField({ states, setStates }) {
  const handleDateChange = (e) => {
    setStates((prev) => ({ ...prev, celebrationDate: e.target.value }));
  };

  return (
    <input
      type="date"
      className="border flex-1 rounded px-2 py-1 text-sm w-full"
      value={states.celebrationDate || ""}
      onChange={handleDateChange}
    />
  );
}
