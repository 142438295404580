import React from "react";

export default function WhoIsCelebratingField({ states, setStates }) {
  const { name, phoneNumber, age, relationShip, gender } =
    states.whoIsCelebrating;

  const handleChange = (field, value) => {
    setStates((prev) => ({
      ...prev,
      whoIsCelebrating: {
        ...prev.whoIsCelebrating,
        [field]: value,
      },
    }));
  };

  return (
    <div className="space-y-2">
      <label className="block text-sm font-semibold">Who is Celebrating?</label>
      <div className="flex flex-col gap-2">
        <input
          type="text"
          className="border rounded px-2 py-1 text-sm"
          placeholder="Name"
          value={name}
          onChange={(e) => handleChange("name", e.target.value)}
        />
        <input
          type="text"
          className="border rounded px-2 py-1 text-sm"
          placeholder="Phone Number"
          value={phoneNumber}
          onChange={(e) => {
            const val = e.target.value;
            if (/^\d{0,10}$/.test(val)) {
              handleChange("phoneNumber", val);
            }
          }}
        />
        <input
          type="number"
          className="border rounded px-2 py-1 text-sm"
          placeholder="Age"
          value={age || ""}
          onChange={(e) => handleChange("age", e.target.value)}
        />
        <input
          type="text"
          className="border rounded px-2 py-1 text-sm"
          placeholder="Relationship"
          value={relationShip}
          onChange={(e) => handleChange("relationShip", e.target.value)}
        />
        <select
          className="border rounded px-2 py-1 text-sm"
          value={gender || ""}
          onChange={(e) => handleChange("gender", e.target.value)}
        >
          <option value="">Select Gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="other">Other</option>
        </select>
      </div>
    </div>
  );
}
