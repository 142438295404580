import React, { useState } from "react";
import { motion } from "framer-motion";
import { BiCopy } from "react-icons/bi";
import { MdDelete, MdEdit } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import moment from "moment";
import Replies from "./Replies";

/**
 * Color palettes for each note index
 * format: [bgColor, textColor, borderColor]
 */
const colorPallets = [
  ["bg-orange-100", "text-orange-800", "border-orange-400"],
  ["bg-blue-100", "text-blue-800", "border-blue-300"],
  ["bg-green-100", "text-green-800", "border-green-300"],
  ["bg-yellow-100", "text-yellow-800", "border-yellow-300"],
  ["bg-red-100", "text-red-800", "border-red-300"],
  ["bg-indigo-100", "text-indigo-800", "border-indigo-300"],
  ["bg-purple-100", "text-purple-800", "border-purple-300"],
  ["bg-pink-100", "text-pink-800", "border-pink-300"],
  ["bg-gray-100", "text-gray-800", "border-gray-300"],
  ["bg-blue-100", "text-blue-800", "border-blue-300"],
  ["bg-green-100", "text-green-800", "border-green-300"],
  ["bg-yellow-100", "text-yellow-800", "border-yellow-300"],
  ["bg-red-100", "text-red-800", "border-red-300"],
  ["bg-indigo-100", "text-indigo-800", "border-indigo-300"],
  ["bg-purple-100", "text-purple-800", "border-purple-300"],
];

export default function NoteCard({
  noteIndex,
  noteData, // { note, title, createdAt, admin, tags, replies, ... }
  notes,
  setNotes,
  adminInfo,
  onNotesUpdate,
  selectedNoteIdx,
  setSelectedNoteIdx,
}) {
  const [isEdit, setIsEdit] = useState(false);

  // For animation heights
  const [allRepliesView, setAllRepliesView] = useState(false);

  // Dynamic color based on index
  const paletteIndex = noteIndex % colorPallets.length;
  const [bgColor, textColor, borderColor] = colorPallets[paletteIndex];

  // Update note in local state after “Save”
  const handleSave = () => {
    if (!noteData.title || !noteData.note) {
      alert("Title and note are required");
      return;
    }

    // 1) Prepare an updated note object
    const updatedNote = {
      ...noteData,
      createdAt: moment().toISOString(),
      tags: noteData.tags?.includes("edited")
        ? noteData.tags
        : [...(noteData.tags || []), "edited"],
    };

    // 2) Update local state
    const newNotes = [...notes];
    newNotes[noteIndex] = updatedNote;
    setNotes(newNotes);

    // 3) Fire callback with unpopulated structure
    onNotesUpdate(stripLocalFields(newNotes));

    // exit edit mode
    setIsEdit(false);
  };

  // Delete note
  const handleDelete = () => {
    if (!window.confirm("Are you sure you want to delete this note?")) return;

    const newNotes = notes.filter((_, idx) => idx !== noteIndex);
    setNotes(newNotes);
    onNotesUpdate(stripLocalFields(newNotes));
  };

  // Copy note content
  const handleCopy = () => {
    if (navigator?.clipboard) {
      navigator.clipboard.writeText(noteData.note);
      alert("Note copied!");
    }
  };

  // Called when user toggles “Show all replies” or closes replies
  const handleToggleReplies = () => {
    setAllRepliesView(!allRepliesView);
  };

  // For convenience: remove local UI fields like isSelected, isEdit, allRepliesView
  function stripLocalFields(allNotes) {
    return allNotes.map((n) => ({
      note: n.note,
      title: n.title,
      createdAt: n.createdAt,
      tags: n.tags || [],
      admin: typeof n.admin === "object" ? n.admin._id : n.admin,
      replies: (n.replies || []).map((r) => ({
        reply: r.reply,
        createdAt: r.createdAt,
        admin: typeof r.admin === "object" ? r.admin._id : r.admin,
      })),
    }));
  }

  const isSelected = selectedNoteIdx === noteIndex;

  return (
    <motion.div
      animate={{
        height: isSelected ? (allRepliesView ? "30rem" : "18rem") : "4rem",
        maxHeight: isSelected ? (allRepliesView ? "30rem" : "18rem") : "4rem",
        minHeight: isSelected ? "18rem" : "4rem",
      }}
      transition={{
        duration: 0.2,
        ease: "easeInOut",
      }}
      onClick={() => {
        if (!isSelected) {
          setSelectedNoteIdx(noteIndex);
        }
      }}
      className={`relative w-full ${bgColor} ${textColor}
        border-white ${
          isSelected ? "py-4" : "cursor-pointer py-1.5 text-xs"
        } border-2 rounded-xl px-4 flex flex-col overflow-hidden drop-shadow mb-[-2rem] pb-[2rem]`}
    >
      {/* Title row */}
      <div className="flex items-center w-full">
        {!isEdit ? (
          <div className="w-full relative flex items-center gap-2">
            <div
              className={`max-w-[15rem] whitespace-nowrap ${
                isSelected ? "text-base" : "text-sm"
              } font-[500] text-ellipsis overflow-hidden`}
            >
              {noteData?.title}
            </div>
            {/* Copy icon */}
            <BiCopy className="cursor-pointer" onClick={handleCopy} />

            {/* Show “Edited” tag if it has the “edited” label */}
            {noteData.tags?.includes("edited") && (
              <div className="text-xs">Edited</div>
            )}

            {/* If selected, show edit & delete (only if current admin owns it) */}
            {isSelected && (
              <>
                <MdEdit
                  className="absolute right-6 cursor-pointer"
                  onClick={(ev) => {
                    ev.stopPropagation();
                    setIsEdit(true);
                  }}
                />
                {noteData?.admin?._id === adminInfo?._id && (
                  <MdDelete
                    className="absolute right-0 cursor-pointer"
                    onClick={(ev) => {
                      ev.stopPropagation();
                      handleDelete();
                    }}
                  />
                )}
              </>
            )}

            {/* If *not* selected, show admin name on far right */}
            {!isSelected && (
              <div className="absolute right-0 text-xs">
                - {noteData?.admin?.userName}
              </div>
            )}
          </div>
        ) : (
          /* Edit Title */
          <div className="w-full flex items-center gap-2 relative">
            <input
              type="text"
              placeholder="Title"
              value={noteData.title}
              onChange={(e) => {
                const newNotes = [...notes];
                newNotes[noteIndex] = {
                  ...noteData,
                  title: e.target.value,
                };
                setNotes(newNotes);
              }}
              className={`${bgColor} w-full min-w-full text-base font-[500]`}
            />
            <RxCross2
              className="absolute right-0 cursor-pointer"
              onClick={(ev) => {
                ev.stopPropagation();
                setIsEdit(false);
              }}
            />
          </div>
        )}
      </div>

      {/* Note content */}
      {isEdit ? (
        <textarea
          placeholder="Note..."
          value={noteData.note}
          onChange={(e) => {
            const newNotes = [...notes];
            newNotes[noteIndex] = {
              ...noteData,
              note: e.target.value,
            };
            setNotes(newNotes);
          }}
          className={`${bgColor} mt-2 w-full min-w-full text-sm min-h-[6rem] overflow-scroll`}
        />
      ) : (
        <div className="text-sm min-h-[5.8rem] w-full max-h-[5.8rem] overflow-scroll mt-2">
          {noteData.note}
        </div>
      )}

      {/* Footer row: left=admin name or 'save', right=timestamp */}
      <div className="w-full flex items-center justify-between mt-auto mb-2">
        <div className="opacity-[.8] text-xs h-full flex items-center">
          - {noteData?.admin?.userName}
        </div>

        {isEdit ? (
          <div
            onClick={(ev) => {
              ev.stopPropagation();
              handleSave();
            }}
            className={`${borderColor} border px-4 py-1 font-[600] rounded-full cursor-pointer`}
          >
            Save
          </div>
        ) : (
          <div className="flex flex-col opacity-[.8] text-xs leading-4 text-right">
            {moment(noteData.createdAt).format("DD MMM, hh:mm A")}
          </div>
        )}
      </div>

      {/* Replies section (only if not editing) */}
      {!isEdit && (
        <Replies
          noteIndex={noteIndex}
          noteData={noteData}
          notes={notes}
          setNotes={setNotes}
          adminInfo={adminInfo}
          onNotesUpdate={onNotesUpdate}
          bgColor={bgColor}
          textColor={textColor}
          borderColor={borderColor}
          allRepliesView={allRepliesView}
          onToggleReplies={handleToggleReplies}
        />
      )}
    </motion.div>
  );
}
