import React from "react";
import { RxCross1 } from "react-icons/rx";

export default function Header({ handleClose, height }) {
  return (
    <div
      className=" w-full absolute top-0 left-0 right-0 border-b border-gray-200 flex items-center justify-between px-2 "
      style={{
        height,
      }}
    >
      <div>
        <h1 className="text-base font-medium">New Enquiry</h1>
      </div>
      <button onClick={handleClose} className="ml-auto text-base ">
        <RxCross1 />
      </button>
    </div>
  );
}
