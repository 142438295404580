import React, { useState } from "react";
import { FaUserShield } from "react-icons/fa";
import ExpertAssignmentModal from "./ExpertAssignmentModal";

/**
 * The small button/icon in the Enquiry Edit Modal (top-right corner).
 * Clicking it opens ExpertAssignmentModal to show assigned experts.
 */
export default function ExpertSwitch({
  enquiryId,
  assignedExperts,
  admins,
  onUpdateAssignedExperts, // will accept the populated assignedExperts array
}) {
  const [showModal, setShowModal] = useState(false);

  // The "current" expert is assumed to be the last entry
  const currentExpertId =
    assignedExperts && assignedExperts.length
      ? assignedExperts[assignedExperts.length - 1].expert?._id
      : null;

  // Look up the expert in the admins array for a name
  const currentExpert =
    admins.find((adm) => adm._id === currentExpertId) || null;

  return (
    <>
      <button
        type="button"
        onClick={() => setShowModal(true)}
        className="flex items-center space-x-1 text-sm px-2 py-1"
      >
        <FaUserShield />
        <span>
          {currentExpert?.userName ? currentExpert.userName : "Assign Expert"}
        </span>
      </button>

      {showModal && (
        <ExpertAssignmentModal
          onClose={() => setShowModal(false)}
          enquiryId={enquiryId}
          assignedExperts={assignedExperts}
          admins={admins}
          onUpdateAssignedExperts={onUpdateAssignedExperts}
        />
      )}
    </>
  );
}
