import React, { useState } from "react";
import { FaPlusCircle } from "react-icons/fa";

export default function AlternateNumbers({ states, setStates }) {
  // local temp input for new phone to add
  const [newAltNumber, setNewAltNumber] = useState("");

  const handleAddAlternateNumber = () => {
    if (!/^\d{10}$/.test(newAltNumber)) return; // ensure it's 10 digits
    setStates((prev) => ({
      ...prev,
      alternateNumbers: [...prev.alternateNumbers, newAltNumber],
    }));
    setNewAltNumber("");
  };

  const handleRemoveAlt = (index) => {
    setStates((prev) => {
      const updated = [...prev.alternateNumbers];
      updated.splice(index, 1);
      return { ...prev, alternateNumbers: updated };
    });
  };

  return (
    <div className="space-y-2">
      <label className="block text-sm font-semibold">Alternate Numbers</label>
      {/* Existing numbers list */}
      {states.alternateNumbers?.length > 0 && (
        <div className="flex flex-col space-y-1">
          {states.alternateNumbers.map((num, index) => (
            <div key={index} className="flex items-center gap-2">
              <input
                className="border rounded px-2 py-1 text-sm flex-1"
                type="text"
                readOnly
                value={num}
              />
              <button
                type="button"
                className="text-xs text-red-500"
                onClick={() => handleRemoveAlt(index)}
              >
                Remove
              </button>
            </div>
          ))}
        </div>
      )}

      {/* New number input + plus button */}
      <div className="flex items-center gap-2">
        <input
          className="border rounded px-2 py-1 text-sm flex-1"
          type="text"
          placeholder="Add new alternate number"
          value={newAltNumber}
          onChange={(e) => {
            // up to 10 digits
            if (/^\d{0,10}$/.test(e.target.value)) {
              setNewAltNumber(e.target.value);
            }
          }}
        />
        {/^\d{10}$/.test(newAltNumber) && (
          <button
            type="button"
            onClick={handleAddAlternateNumber}
            className="text-blue-600 hover:text-blue-800"
          >
            <FaPlusCircle size={18} />
          </button>
        )}
      </div>
    </div>
  );
}
