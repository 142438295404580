import React from "react";
import { useAdminContext } from "../../../../context/AdminContext";
import editEnquiryHelper from "../f/editEnquiryHelper";

export default function Bottom({
  states,
  height,
  setStates,
  selectedTags,
  afterEnquirySave,
}) {
  const {
    adminState: { adminInfo },
  } = useAdminContext();

  return (
    <div
      className="absolute bottom-0 left-0 px-2 pb-2 right-0 flex-center"
      style={{ height }}
    >
      <button
        className="blue-gradient text-sm font-semibold p-2 w-full flex-center h-full rounded-lg"
        onClick={() => {
          editEnquiryHelper().handleEnquirySave({
            states,
            setStates,
            selectedTags,
            callback: afterEnquirySave,
          });
        }}
        // Optionally, disable the button while isCreating is true
        disabled={states.isSavingEnquiry}
      >
        {states.isSavingEnquiry ? (
          <div
            className="loader w-[1rem]"
            style={{
              borderTopColor: "white",
            }}
          ></div>
        ) : (
          "Save"
        )}
      </button>
    </div>
  );
}
