import React, { useState } from "react";
import { IoSend } from "react-icons/io5";
import { FaUser } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import moment from "moment";

/**
 * Renders the "Reply..." input plus any existing replies.
 * Also toggles between "show all replies" vs. collapsed.
 */
export default function Replies({
  noteIndex,
  noteData,
  notes,
  setNotes,
  adminInfo,
  onNotesUpdate,
  bgColor,
  textColor,
  borderColor,
  allRepliesView,
  onToggleReplies,
}) {
  const [replyText, setReplyText] = useState("");

  const repliesCount = noteData.replies?.length || 0;

  // Add a new reply
  const handleReply = () => {
    if (!replyText.trim()) {
      alert("Reply text is required");
      return;
    }

    const newReply = {
      reply: replyText,
      createdAt: moment().toISOString(),
      admin: { _id: adminInfo._id, userName: adminInfo.userName },
    };

    // 1) update local state
    const newNotes = [...notes];
    const newNote = { ...noteData };
    newNote.replies = [...(noteData.replies || []), newReply];
    newNotes[noteIndex] = newNote;

    setNotes(newNotes);
    setReplyText("");

    // 2) Fire the onNotesUpdate callback with stripped version
    onNotesUpdate(stripLocalFields(newNotes));
  };

  // Convert local note objects to “unpopulated” versions
  function stripLocalFields(allNotes) {
    return allNotes.map((n) => ({
      note: n.note,
      title: n.title,
      createdAt: n.createdAt,
      tags: n.tags || [],
      admin: typeof n.admin === "object" ? n.admin._id : n.admin,
      replies: (n.replies || []).map((r) => ({
        reply: r.reply,
        createdAt: r.createdAt,
        admin: typeof r.admin === "object" ? r.admin._id : r.admin,
      })),
    }));
  }

  return (
    <div className="flex flex-col w-full mb-2">
      {/* Reply input */}
      <div
        className={`flex items-center rounded-full w-full border py-1 pl-1 pr-2 ${borderColor}`}
      >
        <input
          className={`w-full ${bgColor} ${textColor} text-sm pl-2 rounded-full`}
          placeholder="Reply..."
          value={replyText}
          onChange={(e) => setReplyText(e.target.value)}
          onClick={(ev) => ev.stopPropagation()} // avoid toggling the card
        />
        <IoSend
          onClick={(ev) => {
            ev.stopPropagation();
            handleReply();
          }}
          className="text-[1.25rem] cursor-pointer"
        />
      </div>

      {/* Show # of replies */}
      {repliesCount > 0 && (
        <div
          onClick={(ev) => {
            ev.stopPropagation();
            onToggleReplies();
          }}
          className="flex justify-end gap-1 items-center cursor-pointer mt-2 mr-1 w-full relative"
        >
          {[...Array(repliesCount)].map((_, idx) => {
            // cap at 8 icons
            if (idx > 7) return null;
            return (
              <div
                key={idx}
                className={`bg-white rounded-full p-1 ${
                  idx !== 0 && " ml-[-1rem]"
                } ${borderColor} border text-[10px]`}
              >
                <FaUser />
              </div>
            );
          })}
          <span className="whitespace-nowrap text-xs mr-2">
            {repliesCount} replie(s)
          </span>
          {allRepliesView && <RxCross2 className="text-base cursor-pointer" />}
        </div>
      )}

      {/* Replies list if expanded */}
      {allRepliesView && (
        <div className="flex flex-col mt-2 min-h-[11.5rem] max-h-[11.5rem] overflow-scroll space-y-3 pb-4">
          {(noteData.replies || []).map((reply, idx) => (
            <div key={idx} className="flex items-start gap-1">
              <div
                className={`bg-white rounded-full p-3 ${borderColor} border text-base`}
              >
                <FaUser />
              </div>
              <div className="flex flex-col w-full relative">
                <div className="text-sm font-[600] flex gap-1">
                  {reply?.admin?.userName}
                  <span className="font-[400] text-xs">
                    - {moment(reply?.createdAt).format("Do MMM - hh:mm A")}
                  </span>
                </div>
                <div className="text-xs w-full">{reply.reply}</div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
