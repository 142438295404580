import React, { useState } from "react";

import { motion } from "framer-motion";
import NotesList from "./c/NotesList";
import NewNoteForm from "./c/NewNoteForm";

/**
 * @param {Object} props
 * @param {Array} props.notes – array of notes (with fully populated admin for display)
 * @param {Function} props.setNotes – sets local notes state
 * @param {Object} props.adminInfo – current admin's info { _id, userName, ... }
 * @param {Function} props.onNotesUpdate – callback invoked with updated notes array (unpopulated)
 * @param {Function} [props.onClose] – optional callback to close/hide notes
 */
export default function Notes({ notes, setNotes, adminInfo, onNotesUpdate }) {
  // Local states:
  const [selectedNoteIdx, setSelectedNoteIdx] = useState(null);

  return (
    <div className="w-full">
      {/* List of existing notes */}
      <NotesList
        notes={notes}
        setNotes={setNotes}
        adminInfo={adminInfo}
        onNotesUpdate={onNotesUpdate}
        {...{ selectedNoteIdx, setSelectedNoteIdx }}
      />

      {/* New note form */}
      <NewNoteForm
        notes={notes}
        setNotes={setNotes}
        adminInfo={adminInfo}
        onNotesUpdate={onNotesUpdate}
        setSelectedNoteIdx={setSelectedNoteIdx}
      />
    </div>
  );
}
